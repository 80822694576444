import {AXIOS} from './backend-config';

export default {
    getBugsList(pageSize, pageNumber) {
        return AXIOS.get('/bug_report', {
            params: {
                pageSize,
                pageNumber
            }
        })
    },
    getBugReportById(id) {
        return AXIOS.get(`/bug_report/${id}`);
    },
    deleteBug(id) {
        return AXIOS.delete(`/bug_report/${id}`);
    },
    getBugLog(id) {
        return AXIOS.get(`/bug_report/${id}/log`);
    },
    getBackendVersion() {
        return AXIOS.get('application-info/versions')
    }
}
