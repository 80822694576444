import Vuex from 'vuex'
import Vue from 'vue';
import auth from '@/modules/auth';
import Cookies from 'js-cookie';
import {v1 as uuidv1} from 'uuid';
import bugsApi from './modules/bugs';

Vue.use(Vuex);

const DEVICE_ID_COOKIE = 'deviceId';
const COOKIE_PATH = '/api/auth';

export default new Vuex.Store({
    state: {
        isAuthorized: null,
        roles: [],
        versions: {
            backend: '-',
            frontend: '-'
        }
    },
    actions: {
        getRoles({dispatch, commit}) {
            return auth.getRoles()
                .then(response => {
                    commit("setRoles", response.data);
                })
                .catch(err => {
                    if (err.response?.status === 401) {
                        dispatch("unauthorize");
                    } else {
                        commit("setRoles", []);
                        console.error("Ошибка получения ролей", err);
                    }
                });
        },
        refreshToken({dispatch, commit}) {
            return auth.refreshToken()
                .then(async () => {
                    commit("setAuthorized", true);
                    await dispatch("getRoles");
                })
                .catch(err => {
                    dispatch("unauthorize", err.response?.status === 401);
                    throw err;
                });
        },
        login({dispatch, commit}, phone) {
            dispatch("createDeviceIdCookie");
            return auth.login(phone);
        },
        loginByPassword({dispatch, commit}, payload) {
            dispatch("createDeviceIdCookie");
            return auth.loginByPassword(payload.login, payload.password)
                .then(async response => {
                    if (response.data.changePassword !== true) {
                        commit("setAuthorized", true);
                        await dispatch("getRoles");
                    }
                    return Promise.resolve(response);
                });
        },
        auth({dispatch, commit}, payload) {
            return auth.authenticate(payload.phone, payload.code)
                .then(async response => {
                    commit("setAuthorized", true);
                    await dispatch("getRoles");
                    return Promise.resolve(response);
                });
        },
        logout({dispatch}) {
            return auth.logout()
                .then(() => dispatch("unauthorize"))
                .catch(err => {
                    if (err.response?.status === 401) {
                        return dispatch("refreshToken")
                            .then(() => {
                                return auth.logout()
                                    .finally(() => dispatch("unauthorize"));
                            })
                            .catch(() => dispatch("unauthorize"));
                    } else {
                        console.error("Ошибка при выходе", err);
                        dispatch("unauthorize");
                    }
                });
        },
        changePassword({}, payload) {
            return auth.changePassword(payload.login, payload.password, payload.newPassword);
        },
        sendLogoutMessage({getters}) {
            if (!getters.isAuthorized && window.parent !== window.self) {
                window.parent.postMessage("LOGOUT", process.env.VUE_APP_ADMIN_UI_URL);
            }
        },
        createDeviceIdCookie({}) {
            if (Cookies.get(DEVICE_ID_COOKIE) == null) {
                Cookies.set(DEVICE_ID_COOKIE, uuidv1(), {
                    path: COOKIE_PATH,
                    domain: process.env.VUE_APP_COOKIE_DOMAIN,
                    expires: parseInt(process.env.VUE_APP_DEVICE_ID_COOKIE_LIFE_TIME)
                });
            }
        },
        unauthorize({commit, dispatch}, removeCookie = true) {
            commit("setAuthorized", false);
            commit("setRoles", []);
            if (removeCookie) {
                Cookies.remove(DEVICE_ID_COOKIE, {
                    path: COOKIE_PATH,
                    domain: process.env.VUE_APP_COOKIE_DOMAIN
                });
            }
            dispatch("sendLogoutMessage");
        },
        getVersions({commit}) {
            return bugsApi.getBackendVersion()
                .then(resp => commit("setVersions", {
                    backend: resp.data?.backend_version ?? '-',
                    frontend: process.env.VUE_APP_VERSION ?? '-'
                }))
                .catch(err => {
                    console.error('Ошибка при получении информации о версии', err);
                    commit("setVersions", {
                        backend: '-',
                        frontend: process.env.VUE_APP_VERSION ?? '-'
                    });
                })
        }
    },
    mutations: {
        setRoles(state, roles) {
            state.roles = roles;
        },
        setAuthorized(state, isAuthorized) {
            state.isAuthorized = isAuthorized;
        },
        setVersions(state, versions) {
            state.versions = versions;
        }
    },
    getters: {
        isAdmin(state) {
            return state.roles.includes('ADMIN');
        },
        isAuthorized(state) {
            return state.isAuthorized;
        },
        isRolesEmpty(state) {
            return state.roles.length === 0;
        },
        getVersions(state) {
            return state.versions;
        }
    }
});
