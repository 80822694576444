<template>
  <b-container v-if="isRolesEmpty" class="pt-2">
    <h1 class="my-4">У вас нет доступа к данному сервису</h1>
  </b-container>
  <b-container v-else class="pt-2">
    <b-table
        hover
        :items="getBugDataTable"
        :fields="fields"
        :current-page="bugList.currentPage"
        ref="bugs_table"
        :per-page="bugList.perPage"
        class="table-bordered table-hover"
        show-empty>
      <template v-slot:empty="scope">
        <h6 class="text-center">Отчёты об ошибках отсутствуют</h6>
      </template>
      <template v-slot:cell(id)="row">
        {{ row.item.id }}
      </template>
      <template v-slot:cell(phone)="row">
        {{ row.item.phone == null ? 'Телефон отсутствует' : row.item.phone }}
      </template>
      <template v-slot:cell(description)="row">
        {{ row.item.description == null ? 'Описание отсутствует' : row.item.description }}
      </template>
      <template v-slot:cell(date)="row">
        {{ row.item.date == null ? 'Дата отуствует' : new Date(row.item.date).toLocaleString() }}
      </template>
      <template v-slot:cell(interaction)="row">
        <b-button class="" variant="primary"
                  title="Просмотр"
                  @click="prepareDetailPage(row.item.id)">
          <b-icon-eye-fill font-scale="0.75"></b-icon-eye-fill>
        </b-button>
        <b-button class="ml-1" variant="primary"
                  v-if="isAdmin"
                  title="Удалить"
                  @click="deleteBug(row.item.id)">
          <b-icon-trash font-scale="0.75"></b-icon-trash>
        </b-button>
      </template>
    </b-table>
    <div class="d-flex">
      <div class="col-md-2 pl-0">
        <b-form-select
            v-model="bugList.perPage"
            class="text-light border-secondary bg-secondary"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
        </b-form-select>
      </div>
      <span class="align-self-center">записей на страницу</span>
      <b-pagination
          v-model="bugList.currentPage"
          class="my-0 ml-auto"
          :total-rows="bugList.totalCount"
          :per-page="bugList.perPage"
          aria-controls="template-table"
          @input="getBugDataTable"
      ></b-pagination>
    </div>
  </b-container>
</template>
<script>
import bugsApi from '@/modules/bugs';
import {mapGetters} from "vuex";

export default {
  name: 'bug-list',
  data() {
    return {
      fields: [
        {
          key: 'id', label: 'Номер'
        },
        {
          key: 'phone', label: 'Телефон пользователя'
        },
        {
          key: 'description', label: 'Описание ошибки'
        },
        {
          key: 'date', label: 'Дата отчёта'
        },
        {
          key: 'interaction', label: 'Взаимодействие'
        }
      ],
      bugList: {
        perPage: 10,
        currentPage: 1,
        totalCount: 0,
      },
    };
  },
  computed: {
    ...mapGetters([
        "isAdmin",
        "isRolesEmpty"
    ])
  },
  methods: {
    getBugDataTable(context) {
      return bugsApi.getBugsList(context.perPage, context.currentPage)
          .catch((error) => {
            let errorMessage;
            if (error.isAxiosError) {
              errorMessage = `Ошибка при запросе списка (код ${error.response?.status})`;
            } else {
              errorMessage = 'Неизвестная ошибка при запросе списка';
            }
            this.$bvToast.toast(errorMessage, {
              title: 'Ошибка',
              autoHideDelay: 5000,
              appendToast: true
            });

            throw error;
          })
          .then(resp => {
            this.bugList.totalCount = resp.data.totalElements;
            return resp.data.bugReports;
          });
    },
    deleteBug(id) {
      //запуск модального окна
      this.$bvModal
          .msgBoxConfirm('Вы уверены, что хотите удалить отчёт?', {
            title: 'Удаление',
            titleTag: 'h6',
            okVariant: 'danger',
            okTitle: 'Удалить',
            cancelTitle: 'Отмена',
            cancelVariant: 'outline-secondary',
            footerClass: 'p-2',
            hideHeaderClose: false,
            centered: true
          })
          .then(value => {
            if (value) {
              bugsApi.deleteBug(id)
                  .then(() => {
                    return this.$refs.bugs_table.refresh();
                  })
                  .catch((error) => {
                    let errorMessage;
                    if (error.isAxiosError) {
                      errorMessage = `Ошибка при запросе удаления отчёта (код ${error.response?.status})`;
                    } else {
                      errorMessage = 'Возникла неизвестная ошибка при удалении отчёта';
                    }
                    this.$bvToast.toast(errorMessage, {
                      title: 'Ошибка',
                      autoHideDelay: 5000,
                      appendToast: true
                    });
                  });
            }
          });
    },
    prepareDetailPage(id) {
      this.$router.push(`/${id}`);
    }
  }
}
</script>
