<template>
  <b-form class="mt-2 ml-5 mb-2 mr-5" v-if="bugData != null">
    <b-form-group label="Идентификатор отчёта">
      <b-form-input v-model="$v.bugData.id.$model"
                    :state="$v.bugData.id.$dirty ? !$v.bugData.id.$error : null"
                    :readonly="true">
      </b-form-input>
    </b-form-group>
    <b-form-group label="Номер телефона пользователя">
      <b-form-input v-model="$v.bugData.phone.$model"
                    :state="$v.bugData.phone.$dirty ? !$v.bugData.phone.$error : null"
                    :readonly="true">
      </b-form-input>
    </b-form-group>
    <b-form-group label="Email пользователя">
      <b-form-input v-model="$v.bugData.email.$model"
                    :state="$v.bugData.email.$dirty ? !$v.bugData.email.$error : null"
                    :readonly="true">
      </b-form-input>
    </b-form-group>
    <b-form-group label="Дата и время создания обновления">
      <b-form-input v-model="new Date($v.bugData.date.$model).toLocaleString()"
                    :state="$v.bugData.date.$dirty ? !$v.bugData.date.$error : null"
                    :readonly="true">
      </b-form-input>
    </b-form-group>
    <b-form-group label="Описание ошибки">
      <b-form-textarea v-model="$v.bugData.description.$model"
                       :state="$v.bugData.description.$dirty ? !$v.bugData.description.$error : null"
                       :readonly="true">
      </b-form-textarea>
    </b-form-group>
    <b-form-group>
      <b-button class="w-25" variant="primary" @click="onEnvironmentClick">
        {{ showEnvironment ? 'Скрыть параметры окружения' : 'Показать параметры окружения' }}
      </b-button>
      <b-table v-if="showEnvironment"
               hover striped
               :state="$v.bugData.environmentInfo.$dirty ? !$v.bugData.environmentInfo.$error : null"
               :items="$v.bugData.environmentInfo.$model"
               class="table-bordered table-hover mt-3">
      </b-table>
    </b-form-group>
    <b-form-group>
      <b-button class="w-25" variant="primary" @click="onLogClick">
        {{ showLog ? 'Скрыть лог ошибки' : 'Показать лог ошибки' }}
      </b-button>
      <b-form-textarea v-if="showLog"
                       v-model="log"
                       style="height: 500px"
                       class="mt-3"
                       :readonly="true">
      </b-form-textarea>
    </b-form-group>
  </b-form>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import bugApi from '@/modules/bugs';
import {mapGetters} from "vuex";

export default {
  mounted() {
    this.reloadBugData(this.$route.params.id);
  },
  data() {
    return {
      showEnvironment: false,
      showLog: false,
      log: null,
      bugData: null,
    };
  },
  validations() {
    let bugDataValidation = {
      id: {
        required
      },
      phone: {
        required
      },
      description: {
        required
      },
      email: {},
      date: {},
      environmentInfo: {},
    };
    return {
      bugData: bugDataValidation
    };
  },
  computed: {
    ...mapGetters([
        "isAdmin"
    ])
  },
  methods: {
    onEnvironmentClick() {
      this.showEnvironment = !this.showEnvironment;
    },
    reloadBugData(id) {
      return bugApi.getBugReportById(id)
          .catch((error) => {
            let errorMessage;
            if (error.isAxiosError) {
              errorMessage = `Ошибка при запросе отчёта (код ${error.response?.status})`;
            } else {
              errorMessage = 'Возникла неизвестная ошибка при запросе отчёта';
            }
            this.$bvToast.toast(errorMessage, {
              title: 'Ошибка',
              autoHideDelay: 5000,
              appendToast: true
            });

            throw error;
          })
          .then(resp => {
            resp.data.environmentInfo = Object.entries(resp.data.environmentInfo)
              .map(entry => {
                return {
                  "Параметр": entry[0],
                  "Значение": entry[1],
                };
              })
            this.bugData = resp.data;
            return resp.data;
          });
    },
    onLogClick() {
      this.showLog = !this.showLog;
      if (!this.showLog || this.log != null) {
        return;
      }

      bugApi.getBugLog(this.bugData.id)
          .then(response => {
            this.log = response.data;
            return Promise.resolve(this.log);
          })
          .catch(() => {
            this.log = "Не удалось получить лог ошибки";
            return Promise.reject(this.log);
          })
    }
  }

}
</script>
